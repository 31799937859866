<template>
  <pz-form-flujo
    v-model="formulario1['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form1_persona.UY.title') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario1"
      status-icon
      :rules="rules_formulario1"
    >
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
                  prop="email"
                  :label="$t('forms_flujo.form2_persona.UY.email')"
                  :error="errors.get('email')"
          >
            <el-input
                    v-model="formulario1.email"
                    :type="'text'"
                    auto-complete="no"
                    :placeholder="$t('forms_flujo.form2_persona.UY.email')"
                    :disabled="true"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
                  prop="telefono"
                  :label="$t('forms_flujo.form1_persona.UY.telefono')"
                  :error="errors.get('telefono')"
          >
            <el-input
                    ref="telefono"
                    v-model="formulario1.telefono"
                    :type="'text'"
                    :placeholder="$t('forms_flujo.form1_persona.UY.telefono')"
                    :disabled="true"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </pz-form-flujo>
</template>
<script>

import {required,type} from "@/commons/utils/Rules";
import 'element-ui/lib/theme-chalk/display.css'
import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import {getFromLocal} from "@/commons/utils/storage";

export default {
    name: 'PzPersonForm2Fenoreste',
    components: { PzFormFlujo},
    mixins: [PzBaseForm1],
    data() {
        return {
            reference: 'formulario1',
            formulario1: {
                telefono: '',
                identity: {document: 1},
                comprobante_domicilio: null,
                comprobante_ingresos: null,
                clearing: null,
                sucursal_id:'',
                email: '',
            },
            rules_formulario1: {
                telefono: [required('')],
                email: [
                    required(''), type({field: '', type: 'email'})
                ]
            },
            pickerOptions1: {
                disabledDate(time) {
                    return Date.now() < time.getTime();
                },
            },
        };
    },
    created: function () {
        let data_client = getFromLocal('data_client');
        if(data_client){
            this.formulario1.identity = data_client.identity,
            this.formulario1.email = data_client.email,
            this.formulario1.telefono = data_client.telefono
        }

    },
    computed:{
        condocumento(){
            var docmun = localStorage.getItem('docnum');
            if(docmun){
                return    true;
            }

            return false
        }
    },
    methods: {
        isReadOnly(data){
            let data_client = getFromLocal('data_client');
            if(data_client)
             return data_client[data]?true:false
            else
             return false;
        }
    },


}
</script>

